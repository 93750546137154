//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-96:_7028,_8480,_4612,_2600,_7860,_2272,_3071,_7476;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-96')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-96', "_7028,_8480,_4612,_2600,_7860,_2272,_3071,_7476");
        }
      }catch (ex) {
        console.error(ex);
      }